import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { loadCSS } from "fg-loadcss";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Link from "./Link";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Icon from "@material-ui/core/Icon";
import logoGradient from "../imgs/logo-gradient.svg";

const useStyles = makeStyles(theme => ({
  appBar: {
    color: theme.palette.primary.main
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 700
  },
  logo: {
    maxHeight: 50,
    marginRight: theme.spacing(2)
  },
  bottomNav: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 999,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[5]
  },
  icon: {
    color: "white"
  },
  linkBtn: {
    margin: theme.spacing(0, 0.5),
    fontWeight: 700
  }
}));

export default function Navbar({ siteTitle }) {
  const url = typeof window !== "undefined" ? window.location.href : "";
  let path = url.substring(url.lastIndexOf("/") + 1);
  console.log(path);
  if (path === "") {
    path = "#";
  }
  const classes = useStyles();
  const theme = useTheme();
  useEffect(() => {
    loadCSS(
      "https://fonts.googleapis.com/icon?family=Material+Icons",
      document.querySelector("#material-icons-css")
    );
  }, []);
  const data = useStaticQuery(graphql`
    query getNavigationItems {
      allContentfulNavigation(sort: { fields: order }) {
        edges {
          node {
            id
            slug
            name
            icon
          }
        }
      }
    }
  `);

  return (
    <div>
      <AppBar position="static" className={classes.appBar} color="inherit">
        <Container>
          <Toolbar style={{ padding: 0 }}>
            <Link to="/" color="inherit">
              <img
                className={classes.logo}
                src={logoGradient}
                alt="Alexander Cardosi Logo"
              />
            </Link>

            <Typography variant="h6" className={classes.title}>
              <Link to="/" color="inherit">
                {siteTitle}
              </Link>
            </Typography>
            <Hidden smDown>
              {data.allContentfulNavigation.edges.map(({ node }) => (
                <Button
                  className={classes.linkBtn}
                  color="inherit"
                  component={Link}
                  style={{
                    borderBottom:
                      path === node.slug
                        ? `solid ${theme.palette.primary.main} 2px`
                        : "none",
                    borderRadius: path === node.slug ? 0 : 10
                  }}
                  to={`/${node.slug}`}
                  key={node.id}
                >
                  {node.name}
                </Button>
              ))}
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      <Hidden mdUp>
        <BottomNavigation className={classes.bottomNav} color="primary">
          {data.allContentfulNavigation.edges.map(({ node }) => (
            <BottomNavigationAction
              className={classes.icon}
              showLabel
              key={node.id}
              component={Link}
              to={`/${node.slug}`}
              label={node.name}
              icon={<Icon className={classes.icon}>{node.icon}</Icon>}
            />
          ))}
        </BottomNavigation>
      </Hidden>
    </div>
  );
}

Navbar.propTypes = {
  transparent: PropTypes.bool,
  siteTitle: PropTypes.string
};
