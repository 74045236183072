import React, { useEffect } from "react";
import { loadCSS } from "fg-loadcss";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";

import CodedIcon from "@material-ui/icons/Code";
import LoveIcon from "@material-ui/icons/Favorite";
import footer from "../imgs/waves_footer.svg";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0
    },
    marginBottom: 55,
    marginTop: theme.spacing(10)
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: "white"
  },
  footerTop: {
    marginBottom: -2,
    width: "100%",
    height: 150,
    backgroundSize: "100% 150px",
    [theme.breakpoints.up("lg")]: {
      height: 300,
      backgroundSize: "100% 300px"
    },
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${footer})`
  },
  footerBottom: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
    padding: theme.spacing(2),
    fontSize: 10,
    display: "inline-flex",
    justifyContent: "center",
    width: "100%"
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  }
}));

export default function Footer() {
  const classes = useStyles();
  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.footerTop} />
      <footer className={classes.footer}>
        <Container>
          <Grid
            container
            spacing={4}
            justify="center"
            className={classes.container}
          >
            <Grid item lg={3}>
              <Button
                color="inherit"
                title="GitHub"
                component="a"
                href="https://github.com/adcar"
              >
                <Icon className={clsx(classes.icon, "fab fa-github")} />
                @adcar
              </Button>
            </Grid>
            <Grid item lg={3}>
              <Button
                color="inherit"
                title="Twitter"
                component="a"
                href="https://twitter.com/adcardosi"
              >
                <Icon className={clsx(classes.icon, "fab fa-twitter")} />
                @adcardosi
              </Button>
            </Grid>
            <Grid item lg={3}>
              <Button
                color="inherit"
                title="CodePen"
                component="a"
                href="https://codepen.io/adcar"
              >
                <Icon className={clsx(classes.icon, "fab fa-codepen")} />
                @adcar
              </Button>
            </Grid>
            <Grid item lg={3}>
              <Button
                color="inherit"
                title="LinkedIn"
                href="https://www.linkedin.com/in/adcardosi"
              >
                <Icon className={clsx(classes.icon, "fab fa-linkedin")} />
                adcardosi
              </Button>
            </Grid>
          </Grid>
        </Container>
      </footer>
      <div className={classes.footerBottom}>
        <CodedIcon
          style={{
            fontSize: 14,
            marginRight: 5
          }}
        />
        with
        <LoveIcon
          style={{
            fontSize: 14,
            marginLeft: 5,
            marginRight: 5
          }}
        />{" "}
        by Alexander Cardosi
      </div>
    </div>
  );
}
